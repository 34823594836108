export const templates = {
  cartItem: document.querySelector("template[name='cart-item']") as HTMLTemplateElement
};

export const dialogs = {
  addItem: {
    self: document.querySelector("dialog[name='add-item']") as HTMLDialogElement,
    close: document.querySelector("dialog[name='add-item'] .close") as HTMLDivElement,
    name: document.querySelector("dialog[name='add-item'] input[name='name']") as HTMLInputElement,
    quantity: document.querySelector("dialog[name='add-item'] input[name='quantity']") as HTMLInputElement,
    unit: document.querySelector("dialog[name='add-item'] select[name='unit']") as HTMLSelectElement,
    confirmAddition: document.querySelector("dialog[name='add-item'] .confirm-addition") as HTMLButtonElement
  }
};

export const cartList = document.querySelector(".cart-list") as HTMLDivElement;
export const addItem = document.querySelector("button.add-item") as HTMLButtonElement;
export const announcement = {
  self: document.querySelector(".announcement") as HTMLDivElement,
  image: document.querySelector(".announcement img") as HTMLImageElement
};
