import { Unit } from "./entities";

export const unitMap = new Map<Unit, string>([
  [Unit.Grams, "g"],
  [Unit.Kilograms, "kg"],
  [Unit.Pieces, "ks"]
]);

export const unitReverseMap = new Map<string, Unit>([
  ["g", Unit.Grams],
  ["kg", Unit.Kilograms],
  ["ks", Unit.Pieces]
]);

export function unitToHuman(unit: Unit) {
  return unitMap.get(unit);
}

export function humanToUnit(value: string) {
  return unitReverseMap.get(value);
}

export function secondsToHuman(total: number) {
  if (total < 1) return " chvílí";

  let days = Math.floor(total / (3600 * 24));
  let hours = Math.floor((total % (3600 * 24)) / 3600);
  let minutes = Math.floor((total % 3600) / 60);
  let seconds = Math.floor(total % 60);

  let daysDisplay = days > 0 ? days + (days == 1 ? " dnem, " : ` dny${hours > 0 ? ", " : ""}`) : "";
  let hoursDisplay = hours > 0 ? hours + (hours == 1 ? " hodinou, " : ` hodinami${minutes > 0 ? ", " : ""} `) : "";
  let minutesDisplay = minutes > 0 ? minutes + (minutes == 1 ? " minutou" : ` minutami${seconds > 0 ? ", " : ""}`) : "";
  let secondsDisplay = seconds > 0 ? seconds + (seconds == 1 ? " sekundou " : ` sekundami`) : "";

  return daysDisplay + hoursDisplay + minutesDisplay + secondsDisplay;
}
