import * as Data from "./data";
import * as Display from "./display";
import * as Extensions from "./extensions";
import * as Events from "./events";
import * as Dom from "./dom";
import CartClient from "./services/cart";

declare global {
  interface Window {
    cartClient: CartClient;
    Data: unknown;
    Display: unknown;
    Extensions: unknown;
    Events: unknown;
    Dom: unknown;
  }
}

async function main() {
  console.clear();

  await registerGlobals();
  await Data.load();

  if (Data.cart.length == 0) {
    Display.addAnnounecment();
  } else {
    for (let item of Data.cart) {
      Display.addCartItem(item);
    }
  }
}

async function registerGlobals() {
  window.Data = Data;
  window.cartClient = Data.cartClient;
  window.Extensions = Extensions;
  window.Events = Events;
  window.Dom = Dom;
}

main();
