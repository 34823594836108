import { CartItem } from "../entities";
import * as Http from "../http";

export const baseUrl = "https://cart.akac.workers.dev";

export default class CartClient {
  async list(order = true) {
    let json = await (await Http.request(baseUrl, "/api/list")).text();

    let items = JSON.parse(json, Http.reviver) as CartItem[];

    if (order)
      items = items.sort((a, b) => {
        return a.addedAt.getTime() - b.addedAt.getTime();
      });

    return items;
  }

  async add(item: CartItem) {
    await Http.request(baseUrl, "/api/add", {
      method: "POST",
      body: JSON.stringify(item),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });
  }

  async remove(id: string) {
    await Http.request(baseUrl, `/api/remove?id=${encodeURIComponent(id)}`, {
      method: "DELETE"
    });
  }
}
