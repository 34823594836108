import * as Display from "./display";
import * as Dom from "./dom";
import * as Data from "./data";
import * as Extensions from "./extensions";
import { CartItem } from "./entities";

export let gifTimeoutId: number = null;

Dom.announcement.image.onclick = async () => {
  let originalSource = Dom.announcement.image.src;
  if (originalSource.endsWith(".gif")) return;

  Dom.announcement.image.src = "/assets/jiggle.gif";

  if (gifTimeoutId) clearTimeout(gifTimeoutId);

  gifTimeoutId = setTimeout(() => {
    Dom.announcement.image.src = originalSource;
  }, 4200);
};

Dom.addItem.onclick = async () => {
  Display.addItemDialog();
};

Dom.dialogs.addItem.close.onclick = async () => {
  Display.removeItemDialog();
};
Dom.dialogs.addItem.confirmAddition.onclick = async () => {
  let name = Dom.dialogs.addItem.name.value.trim();
  if (!name || name == "") return;

  let quantity = Dom.dialogs.addItem.quantity.valueAsNumber;
  if (quantity == null || quantity < 1) return;

  let unit = Extensions.humanToUnit(Dom.dialogs.addItem.unit.value);
  if (unit == null) return;

  Data.addRaw(name, quantity, unit);

  Display.removeItemDialog();
};

export async function quantityPlusClicked(item: CartItem) {
  item.quantity++;

  Data.modify(item);
}

export async function quantityMinusClicked(item: CartItem) {
  if (item.quantity <= 1) return;

  item.quantity--;

  Data.modify(item);
}

export async function completeClicked(item: CartItem) {
  Data.remove(item);
}
