import { CartItem } from "./entities";
import * as Dom from "./dom";
import * as Extensions from "./extensions";
import * as Events from "./events";

export function addCartItem(item: CartItem) {
  let clone = (Dom.templates.cartItem.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

  updateCartItem(clone, item);

  Dom.cartList.append(clone);
}

export function removeCartItem(id: string) {
  let element = document.querySelector(`.cart-item[data-id='${id}']`) as HTMLDivElement;
  if (!element) throw "Cart item not found";

  element.classList.add("fading-out");

  setTimeout(() => {
    element.remove();
  }, 500);
}

export function updateCartItem(element: HTMLDivElement, item: CartItem) {
  element.dataset.id = item.id;

  element.querySelector(".item-name").textContent = item.name;
  element.querySelector(".quantity-amount").textContent = item.quantity.toString();
  element.querySelector(".quantity-unit").textContent = Extensions.unitToHuman(item.unit);

  (element.querySelector(".quantity-plus") as HTMLDivElement).onclick = async () => {
    await Events.quantityPlusClicked(item);
  };
  (element.querySelector(".quantity-minus") as HTMLDivElement).onclick = async () => {
    await Events.quantityMinusClicked(item);
  };

  (element.querySelector(".item-complete") as HTMLDivElement).onclick = async () => {
    await Events.completeClicked(item);
  };
}

export function addItemDialog() {
  Dom.dialogs.addItem.name.value = "";
  Dom.dialogs.addItem.quantity.valueAsNumber = 1;
  Dom.dialogs.addItem.unit.value = "ks";
  Dom.dialogs.addItem.self.showModal();
}

export function removeItemDialog() {
  Dom.dialogs.addItem.self.close();
}

export function addAnnounecment() {
  Dom.announcement.self.classList.remove("hidden");
}

export function removeAnnouncement() {
  Dom.announcement.self.classList.add("hidden");
}
