export enum Unit {
  Grams,
  Kilograms,
  Pieces
}

export interface CartItem {
  id: string;
  name: string;
  quantity: number;
  unit: Unit;
  addedAt: Date;
}
