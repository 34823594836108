import * as Regex from "./regex";

export async function request(base: string, path: string, init?: RequestInit) {
  if (init) {
    init.credentials = "same-origin";
    init.keepalive = true;
    init.mode = "cors";
  }

  let res = await fetch([base, path].join(""), init);

  if (!res.ok) {
    let clone = res.clone();
    let text = await clone.text();
    let preview = !text || text == "" ? "" : text.substring(0, Math.min(text.length, 100));

    throw `Failed to request: ${res.status}${preview && preview.length > 0 ? `=> ${preview}` : ""}`;
  }

  return res;
}

export function reviver(_key: unknown, value: unknown) {
  if (typeof value === "string" && Regex.iso8601.test(value)) {
    return new Date(value);
  }

  return value;
}
