import CartClient from "./services/cart";
import { CartItem, Unit } from "./entities";
import * as Display from "./display";
import { v4 as uuid } from "uuid";

export let cartLoader: Promise<void>;
export let cart: CartItem[];

export let cartClient = new CartClient();

export async function loadCart() {
  cart = await cartClient.list();
}

export async function load() {
  cartLoader = loadCart();

  await Promise.all([cartLoader]);
}

export async function addRaw(name: string, quantity: number, unit: Unit) {
  return await add({
    id: uuid(),
    name,
    quantity,
    unit,
    addedAt: new Date()
  });
}

export async function add(item: CartItem) {
  Display.removeAnnouncement();
  Display.addCartItem(item);

  cart.push(item);

  await cartClient.add(item);
}

export async function remove(item: CartItem) {
  Display.removeCartItem(item.id);

  cart = cart.filter((x) => x.id != item.id);
  if (cart.length == 0) Display.addAnnounecment();

  await cartClient.remove(item.id);
}

export async function modify(item: CartItem) {
  let index = cart.findIndex((x) => x.id == item.id);

  cart[index] = item;

  Display.updateCartItem(document.querySelector(`.cart-item[data-id='${item.id}']`) as HTMLDivElement, item);

  await cartClient.add(item);
}
